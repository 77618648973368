
const Loading = () => {
    return (
        <div className="loading">
            <span className="loader">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </div>
    )
};

export default Loading;