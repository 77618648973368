import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from '@mui/icons-material/Download';


function Popup(e) {
    const { t } = useTranslation();
    const [fade, setFade] = useState(false)

    useEffect(() => {

        const timeout = setTimeout(() => {
            setFade(e.pdfOpenedAnim)

        }, 5)

        return () => clearTimeout(timeout)

    }, [fade]);

    function setFadeAnim(val) {
        e.setPdfOpenedAnim(val)
        setFade(val)
    }
    function download(url, filename) {
        fetch(url).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", filename);
                a.click();
            }
            );
        });
    }

    const zeClass = !fade ? "fadeOut" : "fadeIn"

    const balcony = e.infos.Balcony == 0 ? t("none") : e.infos.Balcony + " " + t("ft");
    return (
        <div className={"popup " + zeClass}>
            <div onClick={() => setFadeAnim(false)} className="bg"></div>
            <div>
                <div className="p_Parent">
                    <div className="p_Infos">
                        <div className="p_Title">
                            <h4>{t("unit")}</h4>
                            <h4>{e.infos.Unit}</h4>
                        </div>
                        <div>
                            <div className="p_Text">
                                <h4>{t("area")}</h4>
                                <h4>{e.infos.Area + " " + t("ft")}</h4>
                            </div>
                            <div className="p_Text">
                                <h4>Type</h4>
                                <h4>{e.infos.Type + "½"}</h4>
                            </div>
                            <div className="p_Text">
                                <h4>{t("chamber(s)")}</h4>
                                <h4>{e.infos.Chambers}</h4>
                            </div>
                            <div className="p_Text">
                                <h4>{t("bath(s)")}</h4>
                                <h4>{e.infos.Bathrooms}</h4>
                            </div>
                            <div className="p_Text">
                                <h4>{t("balcony")}</h4>
                                <h4>{balcony}</h4>
                            </div>
                            <div className="p_Text">
                                <h4>{t("status")}</h4>
                                <h4>{t(String(e.infos.Status).toLowerCase())}</h4>
                            </div>
                            <div className="p_Text">
                                <h4>{t("level")}</h4>
                                <h4>{t("level") + " " + e.level}</h4>
                            </div>
                            <div className="p_Text">
                                <h4>{t("price")}</h4>
                                <h4>{e.infos.Price + " $"}</h4>
                            </div>
                            <div className="p_Desc">
                                <h4>{t("popupDesc0")}</h4>
                                <ul>
                                    <li>{t("popupDesc1")}</li>
                                    <li>{t("popupDesc2")}</li>
                                    <li>{t("popupDesc3")}</li>
                                    <li>{t("popupDesc4")}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="bottom">
                            <div onClick={() => download("./pdf/" + e.infos.PDF + ".pdf?v="+e.version, e.infos.PDF + ".pdf")} className="btnBottom">
                                <DownloadIcon />
                                <p>{t("downloadPlan")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="p_PDF">
                        <div style={{ "backgroundImage": "url(./images/pdf/" + e.infos.PDF + ".jpg?v="+e.version+")" }}></div>
                        {/* {<div><p>{t("viewPlan")}</p></div>} */}
                    </div>
                </div>
                <div onClick={() => setFadeAnim(false)} className="quitBtn"></div>
            </div>
        </div>
    )
}

export default Popup;