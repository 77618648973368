import { read, readFile, utils } from 'xlsx';
import './App.css';
import './Mobile.css';
import { useEffect, useState } from 'react';
import 'react-range-slider-input/dist/style.css';
import i18n from './i18n';
import LocaleContext from './LocaleContext';
import { useTranslation } from 'react-i18next';
import Popup from './Popup';
import useMousePosition from './MousePos';
import { Slider } from '@mui/material';
import Loading from './Loading';
import TuneIcon from '@mui/icons-material/Tune';

const maxLvl = 29;
function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}
var tmpCurrLvl = 1;
var unitsLength = 0;
var oldTmpLvl = "lvl_01";
var currUnitValues;
var allData;
const version = "1.0.3";

const ua = navigator.userAgent.toLowerCase(), platform = navigator.platform.toLowerCase();
const platformName = ua.match(/ip(?:ad|od|hone)/) ? 'ios' : (ua.match(/(?:webos|android)/) || platform.match(/mac|win|linux/) || ['other'])[0],
    isMobile = /ios|android|webos/.test(platformName);

const appDiv = document.getElementById("app");
const levelsDiv = document.getElementById("levels");
//var initNum = 0;
//console.log(test);

function map_range(zeValue, low1, high1, low2, high2) {
    return low2 + (high2 - low2) * (zeValue - low1) / (high1 - low1);
}

//window.googleDocCallback = function () { return true; };

//const filePath = "./DataSheet.xlsx";
//const filePath = "https://spreadsheets.google.com/tq?tqx=out:html&tq=&key=1OCRR8ZohdUw9HCKd16cApdsIAob-6LiMn9rB1aKKJlM";
//const rdm = Math.floor(Math.random() * 1000000000000);
// console.log(date)
// const filePath = "https://docs.google.com/spreadsheets/d/e/2PACX-1vSXdnifQXb1GaxI7wy5T83IL9aREcfoTkVOeDbKLNaXzBbOp0dxF3XY4IVTh0EQzTU9skQbQGZOHlIS/pubhtml?gid=0&single=true&rdm="; // EDGE
const filePath = "https://docs.google.com/spreadsheets/d/e/2PACX-1vSv4yfAI-E4opEtejmwNY74SEVrHTbxt34FpyUYiN_xKPHv_Swf2iqi-OzqTa3oMALF39YDLttP-WTI/pubhtml?gid=0&single=true&rdm="; // Symbio

function App() {
    const urlParams = new URLSearchParams(window.location.search);

    const [currLvl, setCurrLvl] = useState(1);
    const [tmp, setTmp] = useState(0);
    const [locale, setLocale] = useState(i18n.language);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        var lvl, lvlName;
        const unitOverlay = document.getElementById("overlayUnits");
        // const lng = urlParams.get('lang') ? urlParams.get('lang') : "fr";
        // ------------------ Levels
        if (currLvl == 1) {
            lvl = "lvl_01"
            lvlName = "_" + String(i18n.language).toUpperCase()
        } else if (currLvl == 2) {
            lvl = "lvl_02"
            lvlName = ""
        } else if (currLvl >= 3 && currLvl <= 7) {
            lvl = "lvl_03-07"
            lvlName = ""
        } else if (currLvl >= 8 && currLvl <= 22) {
            lvl = "lvl_08-22"
            lvlName = ""
        } else if (currLvl >= 23 && currLvl <= 28) {
            lvl = "lvl_23-28"
            lvlName = ""
        } else {
            lvl = "lvl_29"
            lvlName = "_" + String(i18n.language).toUpperCase()
        }

        //console.log(allLvl)
        test.changeArtboard("Symbio_02", lvl)
        unitOverlay.style.backgroundImage = "url(./images/SYMBIO_" + lvl + lvlName + ".png)"
        // console.log("url(./images/SYMBIO_" + lvl + lvlName + ".png)");
        if (oldTmpLvl !== lvl) { setLoading(true); }
        oldTmpLvl = lvl;

        //setVariationTimer(!variationTimer)

        //setLoading(true);
        generateUnitNum(tmpCurrLvl)

    }, [currLvl]);

    const [variationTimer, setVariationTimer] = useState(false)

    // // -------------------------------------- Tweak
    useEffect(() => {
        const timeout = setTimeout(() => {
            //setShow(true)
            setLoading(false);

        }, 1500)

        return () => clearTimeout(timeout)

    }, [variationTimer])


    function generateUnitNum(zeLvl) {
        // ------------------ Building
        const allLvl = document.querySelectorAll("#image-map-pro-building .imp-object-poly.imp-object");
        //console.log(allLvl)
        allLvl.forEach((el) => {
            if (el.getAttribute('data-title').includes(pad(zeLvl))) {
                el.classList.add("active");
            } else {
                el.classList.remove("active");
            }
        })

        const allUnit = document.querySelectorAll("#image-map-pro-lvl .imp-object-text.imp-object[data-title*='t_']");
        // const allUnit2 = document.querySelectorAll("#image-map-pro-lvl .imp-object-poly.imp-object[data-title*='u_']");
        //unitsLength = allUnit.length;
        setCurrUnits([]);

        allUnit.forEach((el) => {
            const num = zeLvl + el.getAttribute('data-title').substring(2);
            el.classList.add("numbers")
            if (zeLvl < 10) {
                el.classList.add("small")
            } else {
                el.classList.remove("small")
            }
            el.innerHTML = num
            setCurrUnits(oldArray => [...oldArray, getUnitInfos(num)])

            /*const foreign = document.createElementNS("http://www.w3.org/2000/svg", "foreignObject");
            foreign.style.width = "100%"
            foreign.style.height = "100%"
            const nodeNum = document.createElement("p")
            nodeNum.classList.add("numbers")
            nodeNum.appendChild(document.createTextNode(num));
            //nodeNum.style.left = elCenter(el, num).x + "px"
            //nodeNum.style.top = elCenter(el, num).y + "px"
            foreign.appendChild(nodeNum)
            console.log(el);
            el.appendChild(foreign)*/

        })
        //console.log("initNumLoad: "+initNumLoad);
        // if (initNumLoad < 3 && initNumLoad > 0) {
        //     setInitNumLoad((count) => count + 1)
        // }
        //console.log(allLvl);
    }

    function getUnitInfos(unit) {
        var rtn = null;
        allData?.forEach((e, i) => {
            if (e.Unit == unit) {
                rtn = e;
            }
        })
        return rtn;
    }

    const [currFilter, setCurrFilter] = useState({ areaMin: 0, areaMax: 0, priceMin: 0, priceMax: 0, chamberMin: 0, chamberMax: 0, bathMin: 0, bathMax: 0, status: "Available", forceUpdate: false })
    const [minMaxFilter, setminMaxFilter] = useState({ areaMin: 0, areaMax: 0, priceMin: 0, priceMax: 0, chamberMin: 0, chamberMax: 0, bathMin: 0, bathMax: 0 })
    const [currUnits, setCurrUnits] = useState([]);
    const [initNum, setInitNum] = useState(0);
    const [initNumLoad, setInitNumLoad] = useState(0);

    useEffect(() => {
        const allUnit = document.querySelectorAll("#image-map-pro-lvl .imp-object-poly.imp-object[data-title*='u_']");
        //console.log(currFilter)
        if (allUnit.length > 0) {
            allUnit.forEach(el => {
                const num = currLvl + el.getAttribute('data-title').substring(2);
                //console.log(num)
                currUnits.forEach(el2 => {
                    //console.log(el2.Unit)
                    if (el2?.Unit == String(num)) {
                        // if (currFilter.areaMin <= el2.Area && currFilter.areaMax >= el2.Area && currFilter.priceMin <= el2.Price && currFilter.priceMax >= el2.Price && el2.Status == currFilter.status && currFilter.chamber.includes(el2.Chambers) && currFilter.bath.includes(el2.Bathrooms)) {
                        if (currFilter.areaMin <= el2.Area &&
                            currFilter.areaMax >= el2.Area &&
                            currFilter.chamberMin <= el2.Chambers &&
                            currFilter.chamberMax >= el2.Chambers &&
                            currFilter.bathMin <= el2.Bathrooms &&
                            currFilter.bathMax >= el2.Bathrooms &&
                            currFilter.priceMin <= el2.Price &&
                            currFilter.priceMax >= el2.Price) {

                            el.classList.remove("disabled")

                        } else {
                            el.classList.add("disabled")
                        }
                        if (el2.Status == "Available") {
                            el.classList.remove("rented")
                        } else {
                            el.classList.add("rented")
                        }
                    }
                });
                //console.log(currUnits)
            })
        }
    }, [currFilter]);

    /* ------------------------------------------------------------------------------------------------------------------------ IMAGE MAP EVENTS */

    const [hoveredLevel, setHoveredLevel] = useState(0);
    const [levelTooltip, setLevelTooltip] = useState(false);
    const [hoveredUnit, setHoveredUnit] = useState(0);
    const [unitTooltip, setUnitTooltip] = useState(false);
    const mousePosition = useMousePosition();

    useEffect(() => {
        if (tmp == 1) {
            setLoading(true);
            urlParams.get('lang') && i18n.changeLanguage(urlParams.get('lang'));

            test.subscribe((action) => {
                if (action.type === 'objectClick') {
                    //console.log(action.payload);
                    if (action.payload.map == "Symbio_01") {
                        const num = parseInt(action.payload.object.substring(1));
                        setCurrLvl(num)
                        tmpCurrLvl = num;
                    }
                    if (action.payload.map == "Symbio_02") {
                        const num = action.payload.object.substring(2);
                        //console.log(currLvl);
                        setPdfInfos(num)
                        setPdfOpenedAnim(true)
                    }
                } else if (action.type === 'artboardChange') {
                    if (action.payload.map == "Symbio_02") {
                        generateUnitNum(tmpCurrLvl)
                        setLoading(false);
                        //setVariationTimer(!variationTimer)
                        //console.log(tmpCurrLvl);
                    }
                } else if (action.type === 'mapInit') { // ----------------------- Image map Initiated
                    if (action.payload.map == "Symbio_02") {
                        //setInitNum((count) => count + 1)
                    }
                    //setInitNumLoad((count) => count + 1)
                } else if (action.type === 'objectHighlight') { // ----------------------- Image map Initiated
                    if (action.payload.map == "Symbio_01") {
                        const num = parseInt(action.payload.object.substring(1));
                        setHoveredLevel(num)
                        setLevelTooltip(true)
                    } else if (action.payload.map == "Symbio_02") {
                        if (!action.payload.object.includes("elevator")) {
                            const num = tmpCurrLvl + action.payload.object.substring(2);
                            setHoveredUnit(num)
                            setUnitTooltip(true)
                            currUnitValues = getUnitInfos(num)
                        }
                    }
                } else if (action.type === 'objectUnhighlight') { // ----------------------- Image map Initiated
                    if (action.payload.map == "Symbio_01") {
                        setLevelTooltip(false)
                    } else if (action.payload.map == "Symbio_02") {
                        setUnitTooltip(false)
                    }
                }
            })
            // Import Excel file
            //setInitNum((count) => count + 1)

        } else {
            setTmp(1)
        }
    }, [tmp])

    // useEffect(() => {
    //     console.log(initNumLoad)
    //     if (initNumLoad >= 3) {
    //         //generateUnitNum(tmpCurrLvl)
    //         setLoading(false);
    //     }
    // }, [initNumLoad]);


    useEffect(() => {
        setCurrFilter({
            ...currFilter,
            forceUpdate: !currFilter.forceUpdate
        })
    }, [currUnits]);

    useEffect(() => {
        //console.log(unitsLength);
        var zeAreaMin = Math.trunc(map_range(rangeArea[0], 0, 100, minMaxFilter.areaMin, minMaxFilter.areaMax));
        var zeAreaMax = Math.trunc(map_range(rangeArea[1], 0, 100, minMaxFilter.areaMin, minMaxFilter.areaMax));
        var zePriceMin = Math.trunc(map_range(rangePrice[0], 0, 100, minMaxFilter.priceMin, minMaxFilter.priceMax));
        var zePriceMax = Math.trunc(map_range(rangePrice[1], 0, 100, minMaxFilter.priceMin, minMaxFilter.priceMax));
        var zeChamberMin = Math.trunc(map_range(rangeChamber[0], 0, 100, minMaxFilter.chamberMin, minMaxFilter.chamberMax));
        var zeChamberMax = Math.trunc(map_range(rangeChamber[1], 0, 100, minMaxFilter.chamberMin, minMaxFilter.chamberMax));
        var zeBathMin = Math.trunc(map_range(rangeBath[0], 0, 100, minMaxFilter.bathMin, minMaxFilter.bathMax));
        var zeBathMax = Math.trunc(map_range(rangeBath[1], 0, 100, minMaxFilter.bathMin, minMaxFilter.bathMax));

        setCurrFilter({
            ...currFilter,
            areaMin: zeAreaMin,
            areaMax: zeAreaMax,
            priceMin: zePriceMin,
            priceMax: zePriceMax,
            chamberMin: zeChamberMin,
            chamberMax: zeChamberMax,
            bathMin: zeBathMin,
            bathMax: zeBathMax
        })
    }, [minMaxFilter]);




    const [pres, setPres] = useState([]);
    /* Fetch and update the state once */
    useEffect(() => {
        //console.log(allData)
        //if (unitsLength == 1) {
        (async () => {
            /* Download file */
            const f = await (await fetch(filePath + "&date=" + Date.now(), { cache: "no-store" })).arrayBuffer();
            //const f2 = JSON.parse(new TextDecoder().decode(f))
            const wb = readFile(f); // parse the array buffer
            const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
            const data = utils.sheet_to_json(ws); // generate objects
            const copyData = Array.from(data);
            setPres(copyData); // update state
            allData = copyData;
            //setVariationTimer(!variationTimer)
            // console.log(data)
        })();
        //}
        //unitsLength++
    }, []);


    // function transformToGS(data){
    //     var rtn;

    //     data.forEach((e,i)=>{

    //     })

    //     return rtn;
    // }


    useEffect(() => {
        //console.log(pres)
        if (pres.length > 0) {
            setInitNum((count) => count + 1)
            setMinMaxFilterF(pres);

        }
    }, [pres]);

    function setMinMaxFilterF(data) {
        var tmpArea = [];
        var tmpPrice = [];
        var tmpChambers = [];
        var tmpBaths = [];
        data.forEach((e) => {
            tmpArea.push(e?.Area)
            tmpPrice.push(e?.Price)
            tmpChambers.push(e?.Chambers)
            tmpBaths.push(e?.Bathrooms)
        })
        setminMaxFilter({
            ...minMaxFilter,
            areaMin: Math.min.apply(Math, tmpArea),
            areaMax: Math.max.apply(Math, tmpArea),
            priceMin: Math.min.apply(Math, tmpPrice),
            priceMax: Math.max.apply(Math, tmpPrice),
            chamberMin: Math.min.apply(Math, tmpChambers),
            chamberMax: Math.max.apply(Math, tmpChambers),
            bathMin: Math.min.apply(Math, tmpBaths),
            bathMax: Math.max.apply(Math, tmpBaths)
        })
    }

    useEffect(() => {
        if (initNum == 1) {
            generateUnitNum(tmpCurrLvl)
        }
    }, [initNum]);

    function toggle(type, value) {
        var tog = Array.from(currFilter[type]);
        if (tog.includes(value)) {
            const index = tog.indexOf(value);
            tog.splice(index, 1)
        } else {
            tog.push(value)
        }
        //console.log(tog);
        setCurrFilter({
            ...currFilter,
            [type]: tog

        });
    }

    const [rangePrice, setRangePrice] = useState([0, 100]);
    const [rangeArea, setRangeArea] = useState([0, 100]);
    const [rangeChamber, setRangeChamber] = useState([0, 100]);
    const [rangeBath, setRangeBath] = useState([0, 100]);

    function setRange(type, value) {
        const zeMin = type + "Min";
        const zeMax = type + "Max";
        var min = Math.trunc(map_range(value[0], 0, 100, minMaxFilter[zeMin], minMaxFilter[zeMax]));
        var max = Math.trunc(map_range(value[1], 0, 100, minMaxFilter[zeMin], minMaxFilter[zeMax]));
        if (type == "price") {
            setRangePrice(value);
        } else if (type == "area") {
            setRangeArea(value);
        } else if (type == "chamber") {
            setRangeChamber(value);
        } else if (type == "bath") {
            setRangeBath(value);
        }

        setCurrFilter({
            ...currFilter,
            [zeMin]: min,
            [zeMax]: max,
        })

    }


    const isTogled = (type, value) => {
        const tog = Array.from(currFilter[type]);
        const rtn = tog.includes(value) ? "" : " togled";
        return rtn;
    }

    function setLvl(isAdd) {
        if (isAdd) {
            if (currLvl == maxLvl) {
                setCurrLvl(1)
                tmpCurrLvl = 1;
            } else if (currLvl == 12) {
                setCurrLvl(currLvl + 2)
                tmpCurrLvl = tmpCurrLvl + 2
            } else {
                setCurrLvl(currLvl + 1)
                tmpCurrLvl++
            }
        } else {
            if (currLvl == 1) {
                setCurrLvl(maxLvl)
                tmpCurrLvl = maxLvl
            } else if (currLvl == 14) {
                setCurrLvl(currLvl - 2)
                tmpCurrLvl = tmpCurrLvl - 2
            } else {
                setCurrLvl(currLvl - 1)
                tmpCurrLvl--
            }
        }
    }

    const [pdfOpened, setPdfOpened] = useState(false);
    const [pdfOpenedAnim, setPdfOpenedAnim] = useState(false);
    const [pdfInfos, setPdfInfos] = useState(null);

    useEffect(() => {
        var rtn;
        if (!pdfOpenedAnim) {
            const timeout = setTimeout(() => {
                //setShow(true)
                setPdfOpened(false)

            }, 300)

            rtn = () => clearTimeout(timeout)
        } else {
            setPdfOpened(true)
            rtn = undefined;
        }

        return rtn;

    }, [pdfOpenedAnim])

    const toolLvl = levelTooltip ? "fadeIn" : "fadeOut";
    const toolUnit = unitTooltip ? "fadeIn" : "fadeOut";

    const formatText = (type, value) => {
        var rtn;
        //console.log(value);
        if (type == "area") {
            rtn = Math.trunc(map_range(value, 0, 100, minMaxFilter.areaMin, minMaxFilter.areaMax)) + " " + t("ft");
        } else if (type == "price") {
            rtn = Math.trunc(map_range(value, 0, 100, minMaxFilter.priceMin, minMaxFilter.priceMax)) + "$";
        } else if (type == "chamber") {
            rtn = Math.trunc(map_range(value, 0, 100, minMaxFilter.chamberMin, minMaxFilter.chamberMax));
        } else if (type == "bath") {
            rtn = Math.trunc(map_range(value, 0, 100, minMaxFilter.bathMin, minMaxFilter.bathMax));
        }
        return rtn;
    }

    const [mobileMenu, setMobileMenu] = useState("levels");
    const [mobileFilters, setMobileFilters] = useState(false);

    useEffect(() => {
        if (mobileMenu == "levels") {
            appDiv.classList.add("levels")
            appDiv.classList.remove("plans")
        } else {
            appDiv.classList.remove("levels")
            appDiv.classList.add("plans")
        }

    }, [mobileMenu])

    useEffect(() => {
        if (mobileFilters) {
            levelsDiv.classList.add("filtersOpenend")
        } else {
            levelsDiv.classList.remove("filtersOpenend")
        }

    }, [mobileFilters])

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            {loading && <Loading />}
            <div id="mobileMenu">
                <div onClick={() => setMobileMenu("levels")} className={mobileMenu === "levels" ? "active" : ""}>{t("level") + "s"}</div>
                <div onClick={() => setMobileMenu("plans")} className={mobileMenu === "plans" ? "active" : ""}>{"plans"}</div>
            </div>
            {/* <div id="buttonsLang">
                <button onClick={() => i18n.changeLanguage("fr")}>FR</button>
                <button onClick={() => i18n.changeLanguage("en")}>EN</button>
            </div> */}
            {!isMobile && <div style={{ left: mousePosition.x, top: mousePosition.y }} className={'tooltip ' + toolLvl}>
                <p>{t("level") + " " + hoveredLevel}</p>
            </div>}
            {!isMobile && <div style={{ left: mousePosition.x, top: mousePosition.y }} id="unitTooltip" className={'tooltip ' + toolUnit}>
                <div>
                    <h4>{t("unit") + " " + hoveredUnit}</h4>
                    <span></span>
                    <h4>{t(String(currUnitValues?.Status).toLowerCase())}</h4>
                </div>
                <div>
                    <h5>{t("area")}</h5>
                    <h5>{currUnitValues?.Area + " " + t("ft")}</h5>
                </div>
                <div>
                    <h5>{t("chamber(s)")}</h5>
                    <h5>{currUnitValues?.Chambers}</h5>
                </div>
                <div>
                    <h5>{t("bath(s)")}</h5>
                    <h5>{currUnitValues?.Bathrooms}</h5>
                </div>
                <div>
                    <h5>{t("price")}</h5>
                    <h5>{currUnitValues?.Price + " $"}</h5>
                </div>
            </div>}

            {pdfOpened && <Popup
                pdfOpenedAnim={pdfOpenedAnim}
                infos={getUnitInfos(currLvl + pdfInfos)}
                setPdfOpenedAnim={setPdfOpenedAnim}
                level={currLvl}
                version={version}
            />}
            <div onClick={() => window.open("https://www.edgedimension.com/" + t("lng"), "_blank")} id="byEdge" style={{ "backgroundImage": "url(./images/logo-edge_" + t("lng") + ".png)" }}></div>

            <div id="level">{t("level") + " " + currLvl}</div>
            <div id='compass'></div>
            <div id="legend">
                <div className='legendColor'>
                    <span className='c_green'></span>
                    <p>{t("wCrit")}</p>
                </div>
                <div className='legendColor'>
                    <span className='c_red'></span>
                    <p>{t("woCrit")}</p>
                </div>
                <div className='legendColor'>
                    <span className='c_gray'></span>
                    <p>{t("rCrit")}</p>
                </div>
            </div>
            <div id="interface">
                <div onClick={() => setMobileFilters(true)} id="mobileFilters">
                    <TuneIcon fontSize="large" />
                </div>
                <div className='top'>
                    <div onClick={() => setLvl(false)} className='arrow-l arrow'></div>
                    <div>{t("level") + " " + currLvl}</div>
                    <div onClick={() => setLvl(true)} className='arrow-r arrow'></div>
                </div>
                {/* {<div className='flex buttonDiv'>
                    <div className='flex'>
                        <h3>{t("chamber(s)")}</h3>
                        <div className='flex'>
                            <div onClick={() => toggle("chamber", 1)} className={"btn" + isTogled("chamber", 1)}><p>1</p></div>
                            <div onClick={() => toggle("chamber", 2)} className={"btn" + isTogled("chamber", 2)}><p>2</p></div>
                            <div onClick={() => toggle("chamber", 3)} className={"btn" + isTogled("chamber", 3)}><p>3</p></div>
                        </div>
                    </div>
                    <div className='flex'>
                        <h3>{t("bath(s)")}</h3>
                        <div className='flex'>
                            <div onClick={() => toggle("bath", 1)} className={"btn" + isTogled("bath", 1)}><p>1</p></div>
                            <div onClick={() => toggle("bath", 2)} className={"btn" + isTogled("bath", 2)}><p>2</p></div>
                        </div>
                    </div>
                </div>} */}
                <div className='flex filtersParent'>
                    <div id="filtersTop">
                        <h4>{t("filters")}</h4>
                        <div onClick={() => setMobileFilters(false)}></div>
                    </div>
                    <div className='flex rangeParent'>
                        <div className='rangeDiv'>
                            <h3>{t("area")}</h3>
                            <Slider
                                className='rangeSlider'
                                value={rangeArea}
                                onChange={(e, v) => setRange("area", v)}
                                step={0.1}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(v) => formatText("area", v)}
                            />
                            <div>
                                <p className=''>{minMaxFilter.areaMin + " " + t("ft")}</p>
                                <p className=''>{minMaxFilter.areaMax + " " + t("ft")}</p>
                            </div>
                        </div>
                        <div className='rangeDiv'>
                            <h3>{t("chamber(s)")}</h3>
                            <Slider
                                className='rangeSlider'
                                value={rangeChamber}
                                step={100 / (minMaxFilter.chamberMax - minMaxFilter.chamberMin)}
                                onChange={(e, v) => setRange("chamber", v)}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(v) => formatText("chamber", v)}
                            />
                            <div>
                                <p className='offset'>{minMaxFilter.chamberMin}</p>
                                <p className='offset two'>2</p>
                                <p className='offset'>{minMaxFilter.chamberMax}</p>
                            </div>
                        </div>
                        <div className='rangeDiv'>
                            <h3>{t("bath(s)")}</h3>
                            <Slider
                                className='rangeSlider'
                                value={rangeBath}
                                step={100 / (minMaxFilter.bathMax - minMaxFilter.bathMin)}
                                onChange={(e, v) => setRange("bath", v)}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(v) => formatText("bath", v)}
                            />
                            <div>
                                <p className='offset'>{minMaxFilter.bathMin}</p>
                                <p className='offset'>{minMaxFilter.bathMax}</p>
                            </div>
                        </div>
                        <div className='rangeDiv'>
                            <h3>{t("price")}</h3>
                            <Slider
                                className='rangeSlider'
                                value={rangePrice}
                                step={0.1}
                                onChange={(e, v) => setRange("price", v)}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(v) => formatText("price", v)}
                            />
                            <div>
                                <p className='offset'>{minMaxFilter.priceMin + " $"}</p>
                                <p className='offset'>{minMaxFilter.priceMax + " $"}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='flex rangeDiv'>
                    <h3>{t("price")}</h3>
                    <Slider
                        className='rangeSlider'
                        step={0.1}
                        id="rangePrice"
                        defaultValue={[0, 100]}
                        onInput={(e) => setRange("price", e)}
                    />
                    <p className=''>{currFilter.priceMin + " - " + currFilter.priceMax + " $"}</p>
                </div> */}
            </div>
        </LocaleContext.Provider>
    );
}

export default App;
